// npm
import React, { memo } from 'react'
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image'
import { Grid, styled } from '@mui/material'

// components
import { theme } from '@molecules/sections/components/SectionImageAndText'

interface FullImageProps {
  image: IGatsbyImageData
  children: React.ReactNode
  theme: theme
  altLayout: boolean
}

const StyledImage = styled(GatsbyImage)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    bottom: 0,
    right: 0,
    transform: 'translate3d(-50%,-50%,0)',
    width: '100%',
    height: '100%',
  },
}))

const FullImage = ({ children, image, theme, altLayout }: FullImageProps) => {
  return (
    <Grid
      container
      spacing={0}
      sx={{ alignItems: { xs: 'flex-start', lg: 'stretch' }, ...theme }}
    >
      <Grid
        item
        xs={12}
        lg={6}
        xl={6}
        sx={{
          position: 'relative',
          display: { lg: 'flex' },
          alignItems: { lg: 'stretch' },
          order: { lg: altLayout ? 2 : 1 },
        }}
      >
        <StyledImage image={image} alt="" />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        xl={6}
        sx={{
          order: { lg: altLayout ? 1 : 2 },
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}

export default memo(FullImage)
